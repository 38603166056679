import "core-js/modules/web.dom.iterable";
import auth from '@/plugins/auth';
import router, { constantRoutes, dynamicRoutes } from '@/router';
import { getRouters } from '@/api/menu';
import Layout from '@/layout/index';
var permission = {
  state: {
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
    SET_DEFAULT_ROUTES: function SET_DEFAULT_ROUTES(state, routes) {
      state.defaultRoutes = constantRoutes.concat(routes);
    },
    SET_TOPBAR_ROUTES: function SET_TOPBAR_ROUTES(state, routes) {
      state.topbarRouters = routes;
    },
    SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routes) {
      state.sidebarRouters = routes;
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 向后端请求路由数据
        getRouters().then(function (res) {
          var sdata = JSON.parse(JSON.stringify(res.data));
          var rdata = JSON.parse(JSON.stringify(res.data));
          var sidebarRoutes = filterAsyncRouter(sdata);
          var rewriteRoutes = filterAsyncRouter(rdata, false, true);
          var asyncRoutes = filterDynamicRoutes(dynamicRoutes);
          rewriteRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          router.addRoutes(asyncRoutes);
          console.log(asyncRoutes);
          commit('SET_ROUTES', rewriteRoutes);
          commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes));
          commit('SET_DEFAULT_ROUTES', sidebarRoutes);
          commit('SET_TOPBAR_ROUTES', sidebarRoutes);
          resolve(rewriteRoutes);
        });
      });
    }
  }
}; // 遍历后台传来的路由字符串，转换为组件对象

function filterAsyncRouter(asyncRouterMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return asyncRouterMap.filter(function (route) {
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }

    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout;
      } else if (route.component === 'ParentView') {
        route.component = ParentView;
      } else if (route.component === 'InnerLink') {
        route.component = InnerLink;
      } else {
        route.component = loadView(route.component);
      }
    }

    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route['children'];
      delete route['redirect'];
    }

    return true;
  });
}

function filterChildren(childrenMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var children = [];
  childrenMap.forEach(function (el, index) {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(function (c) {
          c.path = el.path + '/' + c.path;

          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }

          children.push(c);
        });
        return;
      }
    }

    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path;

      if (el.children && el.children.length) {
        children = children.concat(filterChildren(el.children, el));
        return;
      }
    }

    children = children.concat(el);
  });
  return children;
} // 动态路由遍历，验证是否具备权限


export function filterDynamicRoutes(routes) {
  var res = [];
  routes.forEach(function (route) {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route);
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route);
      }
    }
  });
  return res;
}
export var loadView = function loadView(view) {
  if (process.env.NODE_ENV === 'development') {
    return function (resolve) {
      return require(["@/views/".concat(view)], resolve);
    };
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return function () {
      return import("@/views/".concat(view));
    };
  }
};
export default permission;